@use "../../styles/vars";
.react-select {
  svg {
    fill: #000000;
    width: 15px;
  }
  &.is-error {
    .react-select {
      &__control {
        border-color: vars.$color-accent;
      }
    }
  }
  .react-select{
    &__control {
      border-radius: 0;
      border: 1px solid #000000;
      outline: none;
      box-shadow: none;
      &:hover {
        border-color: #000000;
        outline: none;
      }
      &--is-focused {
        &:hover {
          border-color: #000000;
          outline: none;
        }
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      padding: 9px 20px 9px 9px;
    }

    &__placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #BFBFBF;
      opacity: 1;
    }
    &__value-container {
      padding-left: 20px;
    }
    &__menu {
      margin-top: -1px;
      outline: none;
      border-radius: 0;
      border: 1px solid #000000;
      border-top: 0;
    }
  }
}

