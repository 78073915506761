@use "../../styles/vars";

:global .react-datepicker-wrapper {
  width: 100%;
  input {
    width: 100%;
    height: 40px;
    border: 1px solid #000000;
    padding: 0 20px;

    font-size: 14px;
    line-height: 38px;
    font-weight: 500;
    color: #000000;

    &::placeholder {
      font-size: 14px;
      line-height: 38px;
      font-weight: 500;
      color: #BFBFBF;
      opacity: 1;
    }
    &.is-error {
      border-color: vars.$color-accent;
    }
  }
}
