@use "../../styles/vars";

.container {
  width: calc(50% - 15px);
  background-color: #FFFFFF;

  @media (max-width: vars.$xs-max) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.header {
  position: relative;
  display: none;
  padding: 11px 10px;
  background: #000000;
  text-align: center;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 23px;
  cursor: pointer;

  @media (max-width: vars.$xs-max) {
    display: block;
  }
}

.arrowIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%) rotate(90deg);
}

.arrowIconOpened {
  transform: translateY(-50%) rotate(-90deg);
}

.body {
  transition: .6s max-height;
  @media (max-width: vars.$xs-max) {
    max-height: 0;
    overflow: hidden;
  }
}

.bodyOpened {
  @media (max-width: vars.$xs-max) {
    max-height: 100rem;
  }
}

.content {
  position: relative;
  padding: 30px 40px 28px;

  @media (max-width: vars.$xs-max) {
    padding: 20px 20px 18px;
  }
}
