@use 'styles/fonts';
@use "styles/vars";

body {
  margin: 0;
  font-family: vars.$font-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'Brandon Grotesque', serif;
}
