@use "../../styles/vars";
.header {
  margin-top: 40px;
  margin-bottom: 10px;
  text-align: center;
}

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
}

.description {
  font-size: 16px;
  line-height: 23px;
  color: #666666;
}

.content {
  background-color: #FFFFFF;
  margin: 0 auto;
  width: 100%;
  padding: 20px 30px;
  @media (min-width: vars.$md-min) {
    padding: 30px 40px 40px;
    width: 704px;
  }
}
.contentPhoto {
  margin-top: 20px;
}

.group {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.groupTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 7px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  @media (min-width: vars.$md-min) {
    width: calc(50% - 10px);
  }
}

.groupAdditionText {
  color: #979797;
  font-weight: normal;
  text-align: right;
}

.groupItem {
  width: 100%;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
  @media (min-width: vars.$md-min) {
    width: calc( 50% - 10px);
  }
  &:nth-child( -n + 2) {
    @media (min-width: vars.$md-min) {
      margin-top: 0;
    }
  }
}

.groupContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 56px;
  margin-bottom: 80px;
  @media (max-width: vars.$xs-max) {
    margin-bottom: 150px;
  }
}
.button {
  margin: 0 5px;

  @media (max-width: vars.$xs-max) {
    min-width: 150px;
  }
}
