@use "../../styles/vars";

$content-padding-horizontal: 30px;

.container {
  height: 100px;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-top: 40px;
  padding-bottom: 30px;
  @media (max-width: vars.$xs-max) {
    height: 115px;
    padding-bottom: 23px;
    padding-top: 70px;
  }
}

.content {
  margin: 0 auto;
  padding-left: $content-padding-horizontal;
  padding-right: $content-padding-horizontal;
  max-width: 1120px + $content-padding-horizontal * 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: vars.$xs-max) {
    position: relative;
    z-index: 1;
  }
}

.menu {
  font-size: 12px;
  color: vars.$secondary-color;
  line-height: 17px;
}

.menuItem {
  display: inline-block;
  color: vars.$secondary-color;
  text-decoration: none;
  vertical-align: bottom;
  font-weight: 600;
  &:before {
    content: '';
    display: inline-block;
    margin: 0 11px;
    width: 2px;
    height: 10px;
    background-color: vars.$secondary-color;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
  @media (max-width: 480px) {
    display: none;
  }
}

.menuLogo {
  display: inline-block;
  color: vars.$secondary-color;
  text-decoration: none;
  vertical-align: bottom;
  font-weight: 600;
  &:before {
    content: '';
    display: inline-block;
    margin: 0 11px;
    width: 2px;
    height: 10px;
    background-color: vars.$secondary-color;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
}

.logo {
  width: 174px;
  height: 30px;
  @media (max-width: vars.$xs-max) {
    width: 130px;
    height: 22px;
  }
}
