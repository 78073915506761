@use "../../styles/vars";

@mixin active {
  margin: -10px;
  height: calc(100% + 20px);
  width: calc(100% + 20px);
  border-color: vars.$primary-color;
  box-shadow: 0 10px 20px rgba(236, 101, 98, 0.2);

  .header {
    background-color: vars.$primary-color;
  }

  .oldPrice {
    margin-bottom: 12px;

    @media (max-width: vars.$xs-max) {
      margin-bottom: 16px;
    }
  }

  .content {
    padding-right: 18px;
    padding-left: 18px;
  }

  .button {
    width: 187px;
    background-color: vars.$primary-color;
    border-color: vars.$primary-color;

    @media (max-width: vars.$xs-max) {
      width: 240px;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 100%;
  background-color: #FFFFFF;
  border: 3px solid #7F756E;
  transition: .3s box-shadow, .3s border-color, .3s margin, .3s height, .3s width;
  cursor: pointer;

  @media (max-width: vars.$xs-max) {
    width: 260px;
  }

  &:hover {
    @media (min-width: vars.$md-min) {
      @include active;
    }
  }
}

.containerActive {
  @media (min-width: vars.$md-min) {
    @include active;
  }
}

:global(.swiper-slide-active) .container {
  @media (max-width: vars.$xs-max) {
    @include active;
  }
}

.header {
  padding: 14px 14px 12px;
  background: #7F756E;
  border: 3px solid #FFFFFF;
  text-align: center;
  color: #FFFFFF;
  transition: .3s background-color;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 2px 8px 16px;
  transition: .3s padding;

  @media (max-width: vars.$xs-max) {
    padding-top: 0;
  }
}

.name {
  font-size: 22px;
  line-height: 31px;
  font-weight: 900;

  @media (max-width: vars.$xs-max) {
    font-size: 24px;
  }
}

.newPrice {
  font-size: 20px;
  line-height: 29px;
  font-weight: 700;
}

.newPricePeriod {
  font-size: 14px;
  font-weight: 500;

  @media (max-width: vars.$xs-max) {
    font-size: 16px;
  }
}

.oldPrice {
  position: relative;
  margin-bottom: 8px;
  padding: 12px 0 15px;
  font-size: 11px;
  line-height: 120%;
  text-align: center;
  color: vars.$secondary-color;
  transition: .3s margin-bottom;

  @media (max-width: vars.$xs-max) {
    font-size: 12px;
    margin-bottom: 12px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: #0F3343;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    content: '';
    position: absolute;
    width: 95px;
    height: 0;
    border: 1px solid vars.$primary-color;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-32.74deg);
  }
}

.oldPriceMain {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;

  @media (max-width: vars.$xs-max) {
    margin-bottom: 0;
  }
}

.oldPriceAdditional {
}

.conditions {
  flex: 1 0 auto;
  margin-bottom: 10px;

  @media (max-width: vars.$xs-max) {
    margin-bottom: 0;
  }
}

.conditionItem {
  position: relative;
  margin: 7px 0 8px;
  padding-left: 18px;
  font-family: vars.$font-primary;
  font-size: 14px;
  line-height: 14px;
  color: #666666;

  div {
    font-family: vars.$font-primary;
    font-size: 14px;
    line-height: 14px;

    b {
      font-family: vars.$font-primary;
      font-size: 14px;
      line-height: 14px;
      font-weight: 900;
    }  
  }

  @media (max-width: vars.$xs-max) {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 16px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 4.5px;
    height: 4.5px;
    top: 4.5px;
    left: 7.5px;
    border-radius: 50%;
    background: vars.$primary-color;
  }
}

.button {
  align-self: center;
  width: 180px;
  transition: .3s width;

  @media (max-width: vars.$xs-max) {
    width: 220px;
  }
}
