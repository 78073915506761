@use "../../styles/vars";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 160px;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-weight: 700;
  transition: .3s background-color, .3s border-color, .3s color;
  border: 2px solid transparent;
  cursor: pointer;

  &-normal {
    background-color: vars.$secondary-color;
    color: #FFFFFF;
    border-color: vars.$secondary-color;

    &:hover {
      background-color: vars.$primary-color;
      border-color: vars.$primary-color;
    }
  }
  &-light {
    background-color: #FFFFFF;
    color: vars.$secondary-color;
    border-color: vars.$secondary-color;
  }

  &-inverse {
    background-color: #FFFFFF;
    color: vars.$secondary-color;
    border-color: vars.$secondary-color;

    &:hover {
      background-color: vars.$secondary-color;
      color: #FFFFFF;
    }
  }
}

.containerDisabled {
  opacity: .2;
  pointer-events: none;
}
