@use "../../styles/vars";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding: 10px 13px;
  z-index: 10;

  @media (max-width: vars.$xs-max) {
    min-height: 80px;
  }
}

.text {
  text-align: center;
  font-size: 16px;
  line-height: 120%;
  color: vars.$secondary-color;
}

.link {
  color: vars.$primary-color;
  text-decoration: underline;
  font-weight: 700;
}