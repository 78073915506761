@use "../../styles/vars";

.container {
  padding: 10px 0;
  margin-bottom: 40px;

  & > div {
    justify-content: center;
  }

  @media (max-width: vars.$xs-max) {
    margin-bottom: 90px;
  }
}

.active {
  background-color: red;
}

.content {
  width: 220px !important;
  height: auto !important;
  align-self: stretch;

  @media (max-width: vars.$xs-max) {
    width: 260px !important;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.paginationBullet {
  width: 10px;
  height: 10px;
  background: #DADADA;
  border-radius: 50%;
  margin: 0 6px;
  cursor: pointer;
  transition: .3s background-color, .3s opacity;

  &:hover {
    opacity: .8;
  }

  &:only-child {
    visibility: hidden;
  }
}

.paginationBulletActive {
  background: vars.$primary-color;

  &:hover {
    opacity: 1;
  }
}