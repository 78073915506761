@use "../../styles/vars";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.popupToBottom {
  @media (max-width: vars.$xs-max) {
    align-items: flex-end;
  }

  .container {
    @media (max-width: vars.$xs-max) {
      border-radius: 5px 5px 0 0;
      max-height: calc(100vh - 50px);
      max-width: 100vw;
    }
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 480px;
  max-width: 100vw;
  max-height: calc(100vh - 40px);
  padding: 30px 20px 40px;
  background: #FFFFFF;
  border-radius: 5px;
  @media (max-width: vars.$xs-max) {
    max-width: calc(100vw - 40px);
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
}

.close {
  top: 20px;
  right: 20px;
  padding: 10px;
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  transition: .3s opacity;

  &:hover {
    opacity: .6;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 1.5px;
    top: 50%;
    left: 50%;
    background-color: #000000;
    border-radius: 2px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.title {
  text-transform: uppercase;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
  color: #000000;
}
