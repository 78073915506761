@use "../../styles/vars";

.container {
  margin-top: 40px;

  @media (max-width: vars.$xs-max) {
    margin-top: 20px;
  }
}

.title {
  margin-bottom: 14px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: vars.$secondary-color;
}

.titleFor {
  @media (max-width: vars.$xs-max) {
    display: none;
  }
}