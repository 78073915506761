@use "../../styles/vars";

.container {
  position: relative;
}

.input {
  width: 100%;
  height: 40px;
  border: 1px solid #000000;
  padding: 0 20px;

  font-size: 14px;
  line-height: 38px;
  font-weight: 500;
  color: #000000;

  &::placeholder {
    font-size: 14px;
    line-height: 38px;
    font-weight: 500;
    color: #BFBFBF;
    opacity: 1;
  }
  &.isError {
    border-color: vars.$color-accent;
  }
  &.isDisabled {
    font-weight: 400;
    border-color: #979797;
    background-color: #F6F6F6;
    color: #979797;
  }
}

