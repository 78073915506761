@use "../../styles/vars";

$padding-top: 20px;
$navigation-horizontal-padding: 30px;
.container {
  position: relative;
  background-color: #F6F6F6;
  flex-grow: 2;
  padding-top: $padding-top;
}

.progress {
  height: 4px;
  width: 100%;
  background-color: #E4E4E4;
  position: absolute;
  top: -$padding-top;
  @media (min-width: vars.$xs-max) {
    display: none;
  }
}
.progressLine {
  height: 4px;
  background-color: vars.$primary-color;
  transition: 600ms;
}

.navigation {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: $navigation-horizontal-padding;
  padding-right: $navigation-horizontal-padding;
}

.item {
  display: none;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  background-color: #FFFFFF;
  border: none;
  color: vars.$primary-color;
  transition: 600ms;
  width: 100%;
  height: 45px;
  @media (min-width: vars.$xs-max) {
    height: 35px;
    display: flex;
    width: 158px;
    margin-left: 10px;
    cursor: pointer;
  }
  &:first-child {
    margin-left: 0;
  }
  &.isSuccess {
    background-color: vars.$primary-color;
    color: #FFFFFF;
  }
  &.isCurrent {
    box-shadow: 0 5px 10px rgba(236, 101, 98, 0.25);
    display: flex;
  }
}


.content {
}

.btn {
  position: absolute;
  background: unset;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  cursor: pointer;
  @media (min-width: vars.$xs-max) {
    display: none;
  }
}

.btnPrev {
  left: $navigation-horizontal-padding + 14px;
  transform: translateY(-50%) rotate(180deg);

}

.btnNext {
  right: $navigation-horizontal-padding + 14px;
}

.btnIcon {
  display: block;
}

.btnDisabled {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
}
