@use "../../styles/vars";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
}

.scroll {
  align-self: stretch;
  height: 225px;
  margin-bottom: 20px;
  @media (max-width: vars.$xs-max) {
    max-height: 30vh;
  }
}

.text {
  font-family: vars.$font-secondary;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
}

.button {
  margin-top: 24px;
  flex-shrink: 0;
}

.signaturePad {
  position: relative;
  height: 111px;
  width: calc(100% - 80px);
  margin: 20px auto 0;
}

.signaturePadText {
  position: absolute;
  top: 10px;
  left: 49px;
  font-family: vars.$font-secondary;
  font-size: 11px;
}

.signaturePadLine {
  position: absolute;
  top: 17px;
  bottom: 12px;
  left: 11px;
  width: 30px;
  border-radius: 12px 0 0 12px;
  border: 2px solid #C4C4C4;
  border-right: none;
}
