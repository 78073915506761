$bg-color: #282c34;
$primary-color: #685944;
$secondary-color: #000000;

$color-accent: #EC6562;

$xs-max: 780px;
$md-min: $xs-max + 1;
$md-max: 1280px;

$font-primary: 'Brandon Grotesque', serif;
$font-secondary: 'Roboto', sans-serif;