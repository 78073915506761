@use "../../styles/vars";

.error {
  display: block;
  color: vars.$color-accent;
  font-weight: 500;
  font-style: italic;
  font-size: 12px;
  line-height: 17px;
}
