@use "../../styles/vars";

.thumb {
  background: #C4C4C4;
  border-radius: 20px;
}

.track {
  right: 5px;
  top: 20px;
  bottom: 20px;
}

.content {
  padding: 10px 20px;
  background: #F6F6F6;
}