@use "../../styles/vars";

$content-padding-horizontal: 30px;

.container {
  margin: 0 auto 100px;
  width: 770px;
  max-width: 100vw;
  padding: 40px $content-padding-horizontal 0;

  @media (max-width: vars.$xs-max) {
    padding-top: 20px;
  }
}

.title {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: vars.$xs-max) {
    display: block;
  }
}

.columnTitle {
  position: relative;
  margin-bottom: 23px;
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  font-weight: 700;

  @media (max-width: vars.$xs-max) {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: #000000;
    left: 0;
    bottom: -12px;
  }
}

.parameter {
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  font-family: vars.$font-secondary;
}

.value {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 16px;
  font-family: vars.$font-secondary;
}

.boldText {
  font-weight: 700;
  font-family: vars.$font-secondary;
  font-size: 16px;
  line-height: 19px;
}

.edit {
  position: absolute;
  width: 33px;
  height: 33px;
  top: 25px;
  right: 15px;
  cursor: pointer;
  padding: 5px;

  @media (max-width: vars.$xs-max) {
    transform: scale(1.5);
    top: 19px;
    right: 10px;
  }
}

.editButton {
  position: absolute;
  top: -9px;
  right: 8px;
  padding: 5px 10px;
  background: #FFFFFF;
  border: 1px solid #DADADA;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  cursor: pointer;
  z-index: 10;

  @media (max-width: vars.$xs-max) {
    top: 5px;
    right: 42px;
  }
}

.avatar {
  position: absolute;
  top: 70px;
  right: 20px;
  width: 60px;
  height: 60px;

  object-fit: cover;

  @media (max-width: vars.$xs-max) {
    position: initial;
    margin-bottom: 20px;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  @media (max-width: vars.$xs-max) {
    margin-bottom: 150px;
  }
}

.button {
  min-width: 150px;
  margin: 0 5px;
}
