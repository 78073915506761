@use "../../styles/vars";

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.input {
  display: none;

  &:checked ~ .title {
    color: #000000;
  }

  &:checked ~ .button {
    background: #231F20;
    border-color: #231F20;

    .check {
      display: initial;
    }
  }
}

.title {
  font-family: vars.$font-secondary;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  transition: .3s color;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 1px solid #9DA1A3;
  border-radius: 4px;
  margin-right: 20px;
  transition: .3s border-color, .3s background-color;
}

.check {
  display: none;
  width: 11px;
  height: 8px;
}