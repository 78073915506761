@use "../../styles/vars";

.btn-book {
  font-family: "Brandon", Sans-serif;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 2px;
  fill: #FF595C;
  color: #FF595C;
  background-color: #FFFFFF;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #FF595C;
  padding: 10px 5px;
  text-decoration:  none;
}
.btn-book:hover {
  background:  #FFEFD5;
  color: #FF595C;
}

.container {
  position: relative;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #000000;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:before {
    background-color: #FFFFFF;
    background-image: url('./background.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:after {
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(11px);
  }
}

.logo {
  margin-bottom: 132px;
  width: 354px;
  height: 60px;

  @media (max-width: vars.$xs-max) {
    display: none;
  }
}

.title {
  margin-bottom: 17px;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  text-transform: uppercase;
  color: #685944;
  font-weight: 700;
  letter-spacing: 0.1em;

  @media (max-width: vars.$xs-max) {
    margin-bottom: 10px;
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.2em;
  }
}

.text {
  max-width: 760px;
  margin-bottom: 27px;
  text-align: center;
  font-size: 19px;
  line-height: 27px;
  font-weight: 700;

  @media (max-width: vars.$xs-max) {
    margin-bottom: 23px;
    font-size: 16px;
    line-height: 23px;
  }
}

.textSmall {
  @extend .text;
  font-size: 16px;
  line-height: 23px;
}

.email {
  @extend .text;
  color: #000000;

  @media (max-width: vars.$xs-max) {
    font-size: 18px;
    line-height: 26px;
  }
}
